import { render, staticRenderFns } from "./aboutOrganization.vue?vue&type=template&id=d7e97618&scoped=true&"
import script from "./aboutOrganization.vue?vue&type=script&lang=js&"
export * from "./aboutOrganization.vue?vue&type=script&lang=js&"
import style0 from "./aboutOrganization.vue?vue&type=style&index=0&id=d7e97618&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7e97618",
  null
  
)

export default component.exports